<template>
  <div class="dashboard-box">
    <template v-if="isShowDashboard">
      <div>
        <div class="mrgb20">
          <el-button-group class="mrgr5 mrgb5">
            <el-button type="primary" size="small" v-for="item in selectTimes" :key="item"
              :plain="curSelectTime != item" @click="selectRange(item)">{{ item === 1 ? "昨日" : "近" + item + "日" }}</el-button>
            <el-button type="primary" size="small" :plain="curSelectTime > 0" @click="selectRange(-1)">自定义时间段</el-button>
          </el-button-group>
          <span v-show="isShowSelectTime">
            <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="small"
              :default-time="['00:00:00', '23:59:59']" @change="getSearchData" :clearable="false"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              align="right">
            </el-date-picker>
            <el-button class="mrgr5 mrgb5" type="primary" size="small" @click="getSearchData()"><i class="fa fa-search"></i> 查询</el-button>
          </span>
        </div>
        <div class="clearb"></div>
      </div>

      <el-row :gutter="15">
        <el-col :xs="24" :lg="12">
          <el-card class="statistics-card mrgb15"><!-- :class="$store.getters.device === 'pc' ? 'statistics-card1' : ''"-->
            <span slot="header">订单收入数据统计<span class="mrgl10 col_danger">(总收入=钱包充值-钱包退款+骑行卡收入+会员卡收入+优惠卡收入+诚信金收入)</span></span>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i1 icon1">￥</div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.totalRevenue}}</div>
                      <div class="title">总收入</div>
                  </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i8 icon1">
                      <i class="el-icon-s-order"></i>
                  </div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.totalOrderCount}}</div>
                      <div class="title">订单总数</div>
                  </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i9 icon1">
                      <i class="el-icon-s-order"></i>
                  </div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.totalValidOrderCount}}</div>
                      <div class="title">有效订单数</div>
                  </div>
              </div>
            </el-col>
            <!--<el-col :xs="24" :sm="12" :md="6">
              <div class="statistics-cell">
                  <div class="icon i10 icon1">
                      <i class="el-icon-s-order"></i>
                  </div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.totalPaidOrderCount}}</div>
                      <div class="title">实收订单数</div>
                  </div>
              </div>
            </el-col>-->
          </el-card>
       
          <el-card class="statistics-card mrgb15">
            <span slot="header">营收数据统计</span>
            <!--<el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i1 icon1">￥</div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.totalRevenue}}</div>
                      <div class="title">总收入</div>
                  </div>
              </div>
            </el-col>-->
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i2 icon1">￥</div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.totalWalletRechargeAmount}}</div>
                      <div class="title">钱包充值金额</div>
                  </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i3 icon1">￥</div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.totalRideCardAmount}}</div>
                      <div class="title">骑行卡金额</div>
                  </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i4 icon1">￥</div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.totalMemberCardAmount}}</div>
                      <div class="title">会员卡金额</div>
                  </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i5 icon1">￥</div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.totalConcessionCardAmount}}</div>
                      <div class="title">优惠卡金额</div>
                  </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i6 icon1">￥</div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.totalHonestyAmount}}</div>
                      <div class="title">诚信金额</div>
                  </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i1 icon1">￥</div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.totalWalletRefund}}</div>
                      <div class="title">钱包退款</div>
                  </div>
              </div>
            </el-col>
            <!--<el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i8 icon1">
                      <i class="el-icon-s-order"></i>
                  </div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.totalOrderCount}}</div>
                      <div class="title">订单总数</div>
                  </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i9 icon1">
                      <i class="el-icon-s-order"></i>
                  </div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.totalValidOrderCount}}</div>
                      <div class="title">有效订单数</div>
                  </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i10 icon1">
                      <i class="el-icon-s-order"></i>
                  </div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.totalPaidOrderCount}}</div>
                      <div class="title">实收订单数</div>
                  </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i11 icon1">
                    <i class="el-icon-s-order"></i>
                  </div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.averageValidOrderCount}}</div>
                      <div class="title">平均有效订单数</div>
                  </div>
              </div>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i2 icon1">
                    <i class="el-icon-s-order"></i>
                  </div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.averageAvailableVehicleCount}}</div>
                      <div class="title">平均可用车辆数</div>
                  </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i1 icon1">￥</div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.averageOrderAmount}}</div>
                      <div class="title">订单平均金额</div>
                  </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i3 icon1">
                    <i class="el-icon-s-order"></i>
                  </div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.averageTurnover}}</div>
                      <div class="title">平均周转车辆数</div>
                  </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i4 icon1">￥</div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.averageTurnoverAmount}}</div>
                      <div class="title">平均周转金额</div>
                  </div>
              </div>
            </el-col>-->
          </el-card>

          <el-card class="statistics-card mrgb15">
            <span slot="header">订单平均数据统计</span>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i4 icon1">￥</div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.averageOrderAmount}}</div>
                      <div class="title">订单平均金额</div>
                  </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i11 icon1">
                    <i class="el-icon-s-order"></i>
                  </div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.averageValidOrderCount}}</div>
                      <div class="title">平均有效订单数</div>
                  </div>
              </div>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i2 icon1">
                    <i class="el-icon-s-order"></i>
                  </div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.averageAvailableVehicleCount}}</div>
                      <div class="title">平均可用车辆数</div>
                  </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i3 icon1">
                    <i class="el-icon-s-order"></i>
                  </div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.averageTurnover}}</div>
                      <div class="title">单车周转</div>
                  </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8">
              <div class="statistics-cell">
                  <div class="icon i7 icon1">￥</div>
                  <div class="info-wrap">
                      <div class="num">{{statisticData.averageTurnoverAmount}}</div>
                      <div class="title">平均周转金额</div>
                  </div>
              </div>
            </el-col>
          </el-card>
        </el-col>

        <el-col :xs="24" :lg="12">
          <el-card class="mrgb15">
            <!--<span slot="header">营收额</span>-->
            <echart id="revenueEchart" title="营收额" width="100%" height="270px" :option="revenueOptions"></echart>
          </el-card>

          <el-card>
            <!--<span slot="header">订单数量</span>-->
            <echart id="orderEchart" title="订单数量" width="100%" height="270px" :option="orderOptions"></echart>
          </el-card>
        </el-col>
      </el-row>
    </template>
    <div class="dashboard-container" v-else>
      <div class="dashboard-text">{{ name }}，欢迎您使用{{ appSiteName }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import permission from "@/mixins/permission"
import * as funReport from "@/api/report"
import echart from "@/components/Echarts/Index"
import moment from "moment"
export default {
  name: 'dashboard',
  mixins: [permission],
  components: { echart },
  data() {
    return {
      listQuery: {
          area: "",
          beginDate: "",
          endDate: "",
          /* type: 0,
          keywords: null,
          page: 1,
          size: window.$common.appPageSize */
      },
      selectTimes: [1, 3, 7, 10, 15, 30],
      curSelectTime: null,
      isShowSelectTime: false,
      selectedTime: [],
      statisticData: {},
      reportData: {},
      revenueOptions: {},
      orderOptions: {},
      isShowDashboard: false
    }
  },
  computed: {
    ...mapGetters([
      'name'
    ])
  },
  watch: {
      "$store.getters.serviceArea"(v) {
          this.mxIsSameActiveTabWithRoute(() => {
              this.initData()
          })
      }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      // 有统计和分析权限
      if (this.isAnalysisDashboard && this.isAnalysisReportView) {
        this.curSelectTime = this.selectTimes[2]
        this.getSearchData()
      } else {
        this.isShowDashboard = false
      }
    },
    setQueryTime(timeArr) {
      this.listQuery.beginDate = timeArr[0] ? moment(timeArr[0]).subtract(1, "days").format("YYYY-MM-DD HH:mm:ss") : ""
      this.listQuery.endDate = timeArr[1] ? moment(timeArr[1]).subtract(1, "days").format("YYYY-MM-DD HH:mm:ss") : ""
    },
    setSelectedTime() {
        let psTime = ""
        let peTime = ""
        if (this.curSelectTime > 0) {
          let timeArr = this.setPickerTime(null, this.curSelectTime, "days")
          this.setQueryTime(timeArr)
        } else {
          if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
            psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
          }
          if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
            peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
          }
          this.listQuery.beginDate = psTime
          this.listQuery.endDate = peTime
          this.selectedTime = [this.listQuery.beginDate, this.listQuery.endDate]
        }
    },
    async getSearchData() {
      if (this.curSelectTime < 0 && (!this.selectedTime[0] || !this.selectedTime[1])) {
        this.warningMsg("请选择日期范围!")
        return false
      }
      this.setSelectedTime()
      this.listQuery.area = this.$store.getters.serviceArea || ""
      let days = moment(this.listQuery.endDate).diff(this.listQuery.beginDate, "day")
      this.listQuery.size = days + 1
      // 请求数据
      window.$common.fullLoading()
      await this.getStatistics()
      await this.getDataList()
    },
    async getStatistics() {
      await funReport.statistics(this.listQuery).then(res => {
        window.$common.closeFullLoading()
        this.statisticData = res
      })
    },
    async getDataList() {
        await funReport.GetItems(this.listQuery).then(response => {
            window.$common.closeFullLoading()
            this.reportData = response
            this.isShowDashboard = true
        })
        this.setRevenueOptions()
        this.setOrderOptions()
    },
    setRevenueOptions() {
      let legendData = ["总收入", "钱包充值", "骑行卡", "会员卡", "诚信金额", "钱包退款"]
      let xAxisData = []
      let series = []
      let days = moment(this.listQuery.endDate).diff(this.listQuery.beginDate, "day")
      // 横坐标
      for (let i = 0; i <= days; i++) {
        let time = moment(this.listQuery.beginDate).add(i, "days")
        xAxisData.push(moment(time).format("YYYY-MM-DD"))
      }
      for (let i = 0; i < legendData.length; i++) {
        let temp = {
          name: legendData[i],
          type: "bar",
          stack: "basic",
          barWidth: "30%",
          barMaxWidth: "20px",
          barMinHeight: 0,
          data: []
        }
        for (let k = 0; k < xAxisData.length; k++) {
          let revenueItem = this.reportData.find(x => {
            return moment(xAxisData[k]).isSame(x.date) 
          })
          let item = 0
          // 有营收数据
          if (revenueItem && revenueItem.revenue) {
            let rev = revenueItem.revenue
            switch(i) {
              case 0: item = rev.totalRevenue || 0; temp.type = "line"; break;
              case 1: item = rev.walletRechargeAmount || 0; break;
              case 2: item = rev.rideCardAmount || 0; break;
              case 3: item = rev.memberCardAmount || 0; break;
              case 4: item = rev.honestyAmount || 0; break;
              case 5: item = rev.walletRefund ? -rev.walletRefund : 0; break;
            }
          }
          temp.data.push(item)
        }
        series.push(temp)
      }
      this.revenueOptions = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: legendData
        },
        grid: {
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        dataZoom: {
          show: true,
          left: "10%",
          width: "83%",
          start: 0,
          end: 100,
          labelFormatter: function (value) {
            let prev = moment(xAxisData[value]).format("YYYY")
            let next = moment(xAxisData[value]).format("MM/DD")
            return prev + "\n" + next;
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLabel: {
            // rotate: 30,
            // interval: 0,
            showMinLabel: true,
            showMaxLabel: true
          },
          data: xAxisData,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#e8e8e8"
            }
          }
        },
        yAxis: {
          type: "value",
          axisTick: {
            
          },
          axisLabel: {
            formatter: "{value}"
          }
        },
        series: series
      }
    },
    setOrderOptions() {
      let legendData = ["订单总数", "有效订单数", "实收订单数"]
      let xAxisData = []
      let series = []
      let days = moment(this.listQuery.endDate).diff(this.listQuery.beginDate, "day")
      // 横坐标
      for (let i = 0; i <= days; i++) {
        let time = moment(this.listQuery.beginDate).add(i, "days")
        xAxisData.push(moment(time).format("YYYY-MM-DD"))
      }
      for (let i = 0; i < legendData.length; i++) {
        let temp = {
          name: legendData[i],
          type: "bar",
          // smooth: false,
          // stack: "basic",
          // areaStyle: {},
          barWidth: "15%",
          barMaxWidth: "20px",
          // barMinHeight: "10",
          data: []
        }
        for (let k = 0; k < xAxisData.length; k++) {
          let orderItem = this.reportData.find(x => {
            return moment(xAxisData[k]).isSame(x.date) 
          })
          let item = 0
          // 有营收数据
          if (orderItem && orderItem.order) {
            let ord = orderItem.order
            switch(i) {
              case 0: item = ord.totalOrderCount || 0; break;
              case 1: item = ord.validOrderCount || 0; break;
              case 2: item = ord.paidOrderCount || 0; break;
            }
          }
          temp.data.push(item)
        }
        series.push(temp)
      }
      this.orderOptions = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: legendData
        },
        grid: {
          containLabel:true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        dataZoom: {
          show: true,
          left: "10%",
          width: "83%",
          start: 0,
          end: 100,
          labelFormatter: function (value) {
            let prev = moment(xAxisData[value]).format("YYYY")
            let next = moment(xAxisData[value]).format("MM/DD")
            return prev + "\n" + next;
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLabel: {
            // rotate: 30,
            // interval: 0,
            showMinLabel: true,
            showMaxLabel: true
          },
          data: xAxisData,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#e8e8e8"
            }
          }
        },
        yAxis: {
          type: "value",
          axisTick: {
            
          },
          axisLabel: {
            formatter: "{value}"
          }
        },
        series: series
      }
    },
    setOptions() {
      let legendData = ["NU", "AU", "PU", "活跃付费率", "总收入", "ARPU", "ARPPU"]
      let xAxisData = []
      let series = []
      for (let i = 1; i <= 31; i++) {
        let time = "2019-08-" + (i < 10 ? "0" + i : i)
        xAxisData.push(time)
      }
      for (let i = 0; i < legendData.length; i++) {
        let temp = {
          name: legendData[i],
          type: "bar",
          stack: "basic",
          barWidth: "30%",
          barMaxWidth: "20px",
          barMinHeight: "10",
          data: []
        }
        for (let k = 0; k < 31; k++) {
          let item = Math.floor(Math.random() * 100)
          temp.data.push(item)
        }
        series.push(temp)
      }
      this.orderOptions = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: legendData
        },
        grid: {
          containLabel:true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        dataZoom: {
          show: true,
          start: 0,
          end: 100
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLabel: {
            rotate: 30,
            interval: 0
          },
          data: xAxisData
        },
        yAxis: {
          type: "value",
          axisTick: {
            
          },
          axisLabel: {
            formatter: "{value}"
          }
        },
        series: series
      }
    },
    selectRange(time) {
      this.curSelectTime = time
      this.isShowSelectTime = time > 0 ? false : true
      this.selectedTime = time > 0 ? [] : this.selectedTime
      if (time > 0) {
        this.getSearchData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dashboard {
  &-box {
    width: 100%;
    .statistics-card {
      padding-bottom: 15px;
    }
    .statistics-card1 {
      .statistics-cell {
        justify-content: center;
      }
    }
    .statistics-cell {
      display: flex;
      align-items: center;
      margin: 10px auto;
      .icon {
        width: 2.7rem;
        height: 2.7rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 1.6rem;
        // margin-right: 10px;
        &.i1 {
          background-color: #ff4a3b;
        }
        &.i2 {
          background-color: #00af51;
        }
        &.i3 {
          background-color: #ae29b0;
        }
        &.i4 {
          background-color: #00d3b1;
        }
        &.i5 {
          background-color: #2babef;
        }
        &.i6 {
          background-color: #ea6c80;
        }
        &.i7 {
          background-color: #e0a300;
        }
        &.i8 {
          background-color: #00bcc3;
        }
        &.i9 {
          background-color: #99ccff;
        }
        &.i10 {
          background-color: #66ffcc;
        }
        &.i11 {
          background-color: #3399ff;
        }
      }
      .info-wrap {
        // flex: 1;
        overflow: hidden;
        margin-left: 10px;
      }
      .num {
        font-size: 1.3rem;
        font-weight: bold;
      }

      .title {
            color: #747474;
      }
    }
  }
  &-container {
    width: 100%;
    min-height: 650px;
    margin: 0;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 6px;
    text-align: center;
    background: #f1f2f3;
    display: table;
  }
  &-text {
    width: 100%;
    font-size: 30px;
    line-height: 46px;
    display: table-cell;
    vertical-align: middle;
  }
}
</style>
